import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import './App.scss';

import { history } from './helpers';
import {
  NotFound,
  SearchTracking,
} from './pages';

export default () => (
  <Router history={history}>
    <div className="App">
    <Switch>
        <Route sensitive strict exact path="/" component={SearchTracking} />
        <Route sensitive strict exact component={NotFound} />
      </Switch>
    </div>
  </Router>
);
