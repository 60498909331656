export const validateShowDash = data => {
  try {
    if (data) {
      return data;
    }
    return '-';
  } catch (error) {
    return '-';
  }
};
