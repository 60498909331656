export const timeFormatHHMM = time => {
  try {
    if (time) {
      const d = time.split(/[^0-9]/);
      const newTime = new Date(d[0],d[1] - 1,d[2],d[3],d[4],d[5]);
      return `${newTime.getHours() >= 10 ? newTime.getHours() : `0${newTime.getHours()}`}:${newTime.getMinutes() >= 10 ? newTime.getMinutes() : `0${newTime.getMinutes()}`}`;
    }
    return '-';
  } catch (err) {
    return '-';
  }
};

export const dateDDMMYYFormat = date => {
  try {
    if (date) {
      const d = date.split(/[^0-9]/);
      const newDate = new Date(d[0],d[1] - 1,d[2],d[3],d[4],d[5]);
      return `${newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`}/${(newDate.getMonth() + 1) > 9 ? newDate.getMonth() + 1 : `0${newDate.getMonth() + 1}`}/${(newDate.getFullYear() + 543).toString().substr(-2)}`;
    }
    return '-';
  } catch (err) {
    return '-';
  }
};
