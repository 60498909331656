import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

import './InputWithIcon.scss';

class InputWithIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'event': 'normal',
    };
  }

  handleEvent = event => this.setState({ event });

  render() {
    const { disabled, placeholder, icon, onChange, type, warning, textWarning, autoComplete, onKeyPress, value, maxLength, id } = this.props;
    const { event } = this.state;

    return (
      <div className="InputWithIcon">
        <div className={`box-input${disabled ? ' disabled' : warning ? ' warning' : ''}${event ? ` ${event}` : ''}`}>
          <span className={`icon ${icon}`} />
          <Input
            type={type ? type : 'text'}
            value={value}
            disabled={disabled}
            className="input"
            placeholder={placeholder}
            onFocus={() => this.handleEvent('focus')}
            onBlur={() => this.handleEvent('normal')}
            onChange={onChange}
            autoComplete={autoComplete ? 'true' : null}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
            id={id}
          />
        </div>
        {
          warning && textWarning ?
            <div className="text-warning">
              <i className="icon fas fa-exclamation-triangle" />
              {textWarning}
            </div>
            : null
        }
      </div>
    );
  }
}

InputWithIcon.propTypes = {
  'placeholder': PropTypes.string,
  'icon': PropTypes.string,
  'disabled': PropTypes.bool,
  'onChange': PropTypes.func,
  'value': PropTypes.string,
  'type': PropTypes.string,
  'warning': PropTypes.bool,
  'textWarning': PropTypes.string,
  'autoComplete': PropTypes.bool,
  'onKeyPress': PropTypes.func,
  'maxLength': PropTypes.string,
  'id': PropTypes.string,
};

export default InputWithIcon;
