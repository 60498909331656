import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const fetchSearchTracking = tracking => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/WebTrackingMulti',
          'param': {
            tracking,
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200) {
        return dispatch({
          'type': commonConstant.SEARCH_TRACKING_SUCCESS,
          'data': data && data.orderList && data.orderList.length > 0 ? data.orderList : null,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.SEARCH_TRACKING_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.SEARCH_TRACKING_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
