import commonConstant from '../../common/commonConstant';

export const searchTracking = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.SEARCH_TRACKING_SUCCESS:
      return { ...state, ...action };
    case commonConstant.SEARCH_TRACKING_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
