export default {
  // image
  'yasLogo': '/images/yas_logo.png',
  'error404': '/images/error_404.png',
  'error500': '/images/error_500.png',
  'errorOop': '/images/error_oop.png',
  'loading': '/images/loading.gif',
  'yasLogoSmall': '/images/yas_logo_sm.png',
  'userDummy': '/images/user_dummy.png',
  // env
  'yasAPI': process.env.REACT_APP_DOMAIN_YAS_API,
  // action type
  //  - sample
  'SEARCH_TRACKING_SUCCESS': 'SEARCH_TRACKING_SUCCESS',
  'SEARCH_TRACKING_FAILURE': 'SEARCH_TRACKING_FAILURE',
};
