import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import Textarea from 'react-expanding-textarea';

import './SearchTracking.scss';

import commonConstant from '../../common/commonConstant';
import { timeFormatHHMM, dateDDMMYYFormat, validateShowDash } from '../../helpers';
import { Loading } from '../../components';
import { fetchSearchTracking } from '../../actions';

class SearchTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      searchList: [],
      loading: false,
      warning: false,
      showDetail: false,
      isCollapse: [],
      orderList: [],
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchTracking && nextProps.searchTracking !== this.props.searchTracking) {
      if (nextProps.searchTracking.data) {
        return this.setState({ loading: false, showDetail: true, orderList: nextProps.searchTracking.data });
      }
      return this.setState({ loading: false, search: '', warning: true, showDetail: false });
    }
  }
  handleChange = e => {
    let data = e.target.value.replaceAll("\n", ",");
    data = data.split(',');
    data = data.slice(0,10);
    let search = data.join().replaceAll(',', '\n');
    let searchList = search.replaceAll("\n", ",");
    this.setState({ search, searchList });
  };
  handleSearch = () => {
    const { search, searchList } = this.state;
    if (search && searchList) {
      let data = searchList.replaceAll("\n", ",").split(',').filter(i => i.trim()).map(i => i.trim()).join();
      // const listOrder = data.replaceAll("\n", ",");
      this.setState({ loading: true, warning: false });
      return this.props.dispatch(fetchSearchTracking(data));
    }
    return null;
  }
  handleClearValue = () => this.setState({ search: '', searchList: [], orderList: [], isCollapse: [], showDetail: false });
  handleShowDetail = data => {
    if (!this.state.showDetail || !data) { return null; }
    return (
      <div className="section-detail">
        <div className="box-title">
          <span className="left">
            <div className="title">Order number</div>
            <div className="number">
              {validateShowDash(data.orderNumber)}
            </div>
          </span>
          <span onClick={() => this.handleCollapse(data.orderNumber)} className={`right ${data.latestStaus === 'Delivered' ? '_delivered': ''}`}>
            <span className="txt">{validateShowDash(data.latestStaus)}</span> <span className="icon icon-arrow-down" />
          </span>
        </div>
        {
          this.state.isCollapse.some(i => i === data.orderNumber) ?
            <div className="box-detail">
              <div className="box-customer">
                <span className="left">
                  <div className="title">Customer Name</div>
                  <div className="description">
                    {validateShowDash(data.customerName)}
                  </div>
                </span>
                <span className="right">
                  <div className="title">AWB No.</div>
                  <div className="description">
                    {validateShowDash(data.awb)}
                  </div>
                </span>
              </div>
              <div className="box-address">
                <div className="title">Address</div>
                <div className="description">
                  {validateShowDash(data.address)}
                </div>
              </div>
            </div> : null
        }
      </div>
    );
  }
  handleShowStatus = orderProgress => {
    if (!this.state.showDetail || !orderProgress.length) { return null; }

    return (
      <div className="box-status">
        <div className={`item${orderProgress[0].orderDateTime ? ' active' : ''}`}>
          <div className="box-icon">
            <span className={`icon ${orderProgress[0].orderDateTime ? 'icon-check' : 'icon-pick'}`} />
          </div>
          <span className="line" />
          <div className="status">Packing</div>
          <div className="date">
            {orderProgress[0].orderDateTime ? `${dateDDMMYYFormat(orderProgress[0].orderDateTime)} ${timeFormatHHMM(orderProgress[0].orderDateTime)}` : null}
          </div>
        </div>
        <div className={`item${orderProgress[1].orderDateTime ? ' active' : ''}`}>
          <div className="box-icon">
            <span className={`icon ${orderProgress[1].orderDateTime ? 'icon-check' : 'icon-pack'}`} />
          </div>
          <span className="line" />
          <div className="status">Ready to ship</div>
          <div className="date">
            {orderProgress[1].orderDateTime ? `${dateDDMMYYFormat(orderProgress[1].orderDateTime)} ${timeFormatHHMM(orderProgress[1].orderDateTime)}` : null}
          </div>
        </div>
        <div className={`item${orderProgress[2].orderDateTime ? ' active' : ''}`}>
          <div className="box-icon">
            <span className={`icon ${orderProgress[2].orderDateTime ? 'icon-check' : 'icon-intransit'}`} />
          </div>
          <span className="line" />
          <div className="status">Intransit</div>
          <div className="date">
            {orderProgress[2].orderDateTime ? `${dateDDMMYYFormat(orderProgress[2].orderDateTime)} ${timeFormatHHMM(orderProgress[2].orderDateTime)}` : null}
          </div>
        </div>
        {this.handleCheckDelivered(orderProgress)}
      </div>
    );
  }
  handleCheckDelivered = data => {
    if (data[3].orderDateTime) {
      return (
        <div className="item active green">
          <div className="box-icon">
            <span className="icon icon-delivered" />
            <span className="icon top icon-check" />
          </div>
          <div className="status">Delivered</div>
          <div className="date">
            {dateDDMMYYFormat(data[3].orderDateTime)} {timeFormatHHMM(data[3].orderDateTime)}
          </div>
        </div>
      );
    } else if (data[4].orderDateTime) {
      return (
        <div className="item active red">
          <div className="box-icon">
            <span className="icon icon-undelivered" />
          </div>
          <div className="status">Undelivered</div>
          <div className="date">
            {dateDDMMYYFormat(data[4].orderDateTime)} {timeFormatHHMM(data[4].orderDateTime)}
          </div>
        </div>
      );
    }

    return (
      <div className="item">
        <div className="box-icon">
          <span className="icon icon-delivered" />
        </div>
        <div className="status">Delivered</div>
        <div className="date" />
      </div>
    );
  }
  handleIntransit = data => {
    return (<div className="intransit">
      {
        data.map((item, key) => (
          <div key={key} className={`intransit-item ${item.intransitStatus === 'Successfully delivered' ? 'active' : ''}`}>
            <div className="in-radio"></div>
            <div className="in-info">
              <div className="in-date">{dateDDMMYYFormat(item.intransitDate)} {timeFormatHHMM(item.intransitDate)}</div>
              <small>{item.intransitArea || '-'}</small>
            </div>
            <div className="in-status">{item.intransitStatus}</div>
          </div>
        ))
      }
  </div>);
  }
  handleCollapse = id => {
    const { isCollapse } = this.state;
    if (isCollapse.length) {
      const items = isCollapse.map(i => i === id ? '' : id).filter(i => i);
      return this.setState({isCollapse: items});
    }
    return this.setState({isCollapse: [id]});
  }

  render() {
    const { search, loading, orderList, showDetail, isCollapse } = this.state;

    return (
      <div className="SearchTracking">
        {loading ? <Loading /> : null}
        <div className="box">
          <img className={`logo ${showDetail ? 'active' : ''}`} src={commonConstant.yasLogo} alt="" />
          <div className={`box-back-btn ${showDetail ? 'active' : ''}`}>
            <span className="icon icon-arrow-left" onClick={this.handleClearValue} />
            <span className="text">Search</span>
          </div>
          {
            !showDetail ? <>
              <h2 className="text-center">ติดตามสถานะ</h2>
              <small className="label">กรอกหมายเลขสิ่งของ (ไม่เกิน 10 หมายเลข)</small>
              <Textarea type="textarea" onChange={this.handleChange} value={search} rows="6" placeholder="Enter order number" />
              <div className="text-center">
                <button onClick={this.handleSearch}>Search</button>
              </div>
              <div className="ex-order"><b>**</b> กด Enter หรือ Comma(,) คั่นหมายเลขสิ่งของ(Order number) กรณีติดตามมากกว่า 1 รายการ</div>
            </> : null
          }
          {
            orderList.map((item, key) => (
              <section key={key} className="sec-info">
                {
                  item.orderDetails.length ? <>
                    {this.handleShowDetail(item.orderDetails[0])}

                    {
                      isCollapse.some(i => i === item.orderDetails[0].orderNumber) ?
                        <div className="section-status">
                          <div className="text-center title">Order status</div>
                          {this.handleShowStatus(item.orderProgress)}
                          {this.handleIntransit(item.intransitprogress)}
                        </div> : null
                    }
                  </> : <div className="not-found">Not found</div>
                }
              </section>
            ))
          }
          {
            showDetail ? <>
              <div className="text-center">
                <button onClick={this.handleClearValue}>Back</button>
              </div>
            </> : null
          }
        </div>
      </div>
    );
  }
}

SearchTracking.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'searchTracking': PropTypes.shape({
    'data': PropTypes.array.isRequired,
  }),
};

const mapStateToProps = ({ searchTracking }) => ({ searchTracking });

export default connect(mapStateToProps)(SearchTracking);
