import React from 'react';
// import { Button } from 'reactstrap';

import './NotFound.scss';

import commonConstant from '../../common/commonConstant';

export default () => (
  <div className="NotFound template-error">
    <div className="box-page">
      <img className="logo" src={commonConstant.yasLogo} alt="" />
      <div className="error-title">404</div>
      <div className="box-detail">
        <img className="error-icon" src={commonConstant.error404} alt="" />
        <div className="error-detail">Sorry! The page not found</div>
        <div className="error-description">The link you folowed probably broken. or the page has been remove</div>
        {/* <Button className="btn-primary" onClick={this.handleRedirect}>
          {content[stateLanguage]['backToHome']}
        </Button> */}
      </div>
    </div>
  </div>
);
